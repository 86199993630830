import type {
	Event as RetailOrderEvent,
	EventApproved,
	EventChargedCard,
	RetailOrder,
} from "@launerlondon/shared";
import {
	fmtPrice,
	getOrderLastEvent,
	getOrderNotes,
	getOrderTotal,
	toDate,
} from "@launerlondon/shared";
import { generateOrderLabel } from "@launerlondon/orders-utils";
import Vue from "vue";
import { apiKey, firestore } from "../lib/firebase";
import printOrderDetails from "../lib/orderPrint";
import "./retailOrderAction";
import "./retailOrderActivities";
import "./retailOrderSummary";

const db = firestore();

declare global {
	interface Window {
		google: any;
	}
}

interface Component extends Vue {
	order: RetailOrder;
	orderId: string;
	tx?: RetailOrderEvent<EventChargedCard>;
	editCustomer: boolean;
	editAddress: boolean;
	editNotes: boolean;
	editOrder: boolean;
	formattedAddress?: string;
}

async function places(query: string): Promise<any[]> {
	return new Promise((resolve) => {
		const mapNode = document.createElement("map");
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
		script.addEventListener("load", () => {
			const service = new self.google.maps.places.PlacesService(mapNode);
			service.findPlaceFromQuery(
				{ query, fields: ["formatted_address"] },
				(results: any) => resolve(results),
			);
		});
		document.body.append(mapNode);
		document.body.append(script);
	});
}

async function updateOrder(this: Component): Promise<void> {
	db.collection("retail-orders")
		.doc(this.orderId)
		.update(this.order)
		.then(() => {
			this.$set(this.$data, "editCustomer", false);
			this.$set(this.$data, "editAddress", false);
			this.$set(this.$data, "editNotes", false);
			this.$set(this.$data, "editOrder", false);
		})
		.catch((err: Error) => alert(err.message));
}

async function translateAddress(this: Component) {
	const { customer } = this.order;
	const { address } = customer;
	const query = [
		address.line1,
		address.city,
		address.state,
		address.postal_code,
		address.country,
	].join(" ");
	const result = (await places(query))[0].formatted_address;
	const text = [
		customer.name,
		...result.split(","),
		address.postal_code,
	].join("<br/>");
	this.$set(this.$data, "formattedAddress", text);
}

async function getShippingLabelUrl(order: RetailOrder) {
	const { name, address } = order.customer;
	const content = [
		name,
		address.line1,
		address.city,
		address.postal_code,
		address.state,
		address.country,
	]
		.filter(Boolean)
		.join("\n");
	return await generateOrderLabel(content);
}

export default Vue.component("retail-order-view", {
	created(this: Component): void {
		db.collection("retail-orders")
			.doc(this.$route.params.orderId)
			.onSnapshot((doc) => {
				const order = doc.data();
				this.$set(this.$data, "orderId", doc.id);
				this.$set(this.$data, "order", order);
				if (this.$props.userType === "factory") {
					getShippingLabelUrl(order as RetailOrder).then((url) => {
						this.$set(this.$data, "shippingLabelUrl", url);
					});
				}
			});
	},
	data() {
		return {
			orderId: undefined,
			order: undefined,
			editCustomer: false,
			editAddress: false,
			editNotes: false,
			editOrder: false,
			formattedAddress: undefined,
			shippingLabelUrl: undefined,
		};
	},
	computed: {
		orderTransactionDate(this: Component): string | undefined {
			if (!this.tx) {
				return;
			}
			const transactionDate = toDate(this.tx.date);
			return transactionDate.toLocaleDateString();
		},
		orderTransactionTime(this: Component): string | undefined {
			if (!this.tx) {
				return;
			}
			const transactionDate = toDate(this.tx.date);
			return transactionDate.toLocaleTimeString();
		},
		orderTransactionTotal(this: Component): string | undefined {
			const { currency } = this.order.payment;
			const total =
				getOrderTotal(this.order, this.order._index.roundFactor).gross[
					currency
				] || 0;

			return fmtPrice(total, currency, "code");
		},
		shippingInfo(this: Component) {
			return getOrderLastEvent(this.order, "shipped")?.extra;
		},
		tx(this: Component): RetailOrderEvent<EventChargedCard> | undefined {
			return getOrderLastEvent(this.order, "charged") as
				| RetailOrderEvent<EventChargedCard>
				| undefined;
		},
		fraudScore(this: Component): number | undefined {
			const event = getOrderLastEvent(this.order, "approved") as
				| RetailOrderEvent<EventApproved>
				| undefined;
			return event?.extra?.fraudScore;
		},
		notesCustomer(this: Component): string | undefined {
			return getOrderNotes(this.order).customer;
		},
		notesFactoryEvent(this: Component): RetailOrderEvent | undefined {
			return this.order.events.find((e) => e.type === "approved");
		},
		hasNonLatinData(this: Component) {
			const chineseChars = /[\u3400-\u9FBF]/;
			const { address } = this.order.customer;
			return [address.line1, address.city, address.state].some((l) =>
				chineseChars.test(l || ""),
			);
		},
		isOrderInProduction(this: Component) {
			return /started|shipped/.test(getOrderLastEvent(this.order).type);
		},
		isOrderShipped(this: Component) {
			return /shipped/.test(getOrderLastEvent(this.order).type);
		},
	},
	methods: {
		updateOrder,
		printOrderDetails,
		translateAddress,
	},
	props: ["userType"],
	template: `
	<section class=retail-order-view v-if=order>

		<div class=retail-order-view__section>
			<h2 class=app-section-header>
				Customer details
				<button v-if="userType==='admin'" class=app-section-header__action @click="editCustomer ? updateOrder() : editCustomer=true">
					{{editCustomer ? 'Save' : 'Edit'}}
				</button>
			</h2>
			<table class=app-kvlist>
				<tbody class=app-kvlist__group>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Name</th>
						<td class=app-kvlist__value>
							<span v-if=!editCustomer>{{order.customer.name}}</span>
							<input v-if=editCustomer v-model=order.customer.name />
						</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Email</th>
						<td class=app-kvlist__value>
							<span v-if=!editCustomer>{{order.customer.email}}</span>
							<input v-if=editCustomer type=email v-model=order.customer.email />
						</td>
					</tr>
				</tbody>
				<tbody class=app-kvlist__group>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Telephone number</th>
						<td class=app-kvlist__value>
							<span v-if=!editCustomer>{{order.customer.phone}}</span>
							<input v-if=editCustomer type=tel v-model=order.customer.phone />
						</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Display/Payment currency</th>
						<td class=app-kvlist__value>
							{{order.customer.currency.toUpperCase()}}/
							{{order.payment.currency.toUpperCase()}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class=retail-order-view__section v-if=tx>
			<h2 class=app-section-header>Transaction details</h2>
			<table class=app-kvlist>
				<tbody class=app-kvlist__group>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Transaction details</th>
						<td class=app-kvlist__value>{{tx?.message}}</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Amount</th>
						<td class=app-kvlist__value>{{order.total.gross[order.payment.currency] || 'N/A'}}</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Date of transaction</th>
						<td class=app-kvlist__value>{{orderTransactionDate}}</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Fraud score</th>
						<td class=app-kvlist__value>{{fraudScore ||  'N/A'}}</td>
					</tr>
				</tbody>
				<tbody class=app-kvlist__group>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Time of transaction</th>
						<td class=app-kvlist__value>{{orderTransactionTime}}</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Payment method</th>
						<td class=app-kvlist__value>
							<template v-if=tx?.extra?.cardType>
								{{tx?.extra?.cardType}}
								<template v-if='order.payment.type !== "offline"'>
									- ending {{tx?.extra?.cardLastFourDigits}}
								</template>
							</template>
							<template v-else>{{order.payment.type}}</template>
						</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>CVC Check</th>
						<td class=app-kvlist__value>
							<template v-if=tx?.extra?.cvcCheck>{{tx?.extra?.cvcCheck}}</template>
							<template v-else>N/A</template>
						</td>
					</tr>
					<tr class=app-kvlist__item>
						<th class=app-kvlist__key>Transaction Ref.</th>
						<td class=app-kvlist__value>
							<template>{{tx?.extra?.transactionId}}</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class=retail-order-view__section>
			<h2 class=app-section-header>
				Order details
				<div class='flex gap-4 items-center'>
					<button v-if="userType==='admin' && !isOrderShipped" class=app-section-header__action @click="editOrder ? updateOrder() : editOrder=true">
						{{editOrder ? 'Save order changes' : 'Edit order extras'}}
					</button>
					<button class=app-section-header__action @click="printOrderDetails(order)">
						Print order details
					</button>
				</div>
			</h2>
			<retail-order-summary :order=order :editMode=editOrder></retail-order-summary>

			<div class=retail-order-view__customer-notes v-if=notesCustomer>
				<h4 class=retail-order-view__customer-notes__key>Customer notes</h4>
				<p class=retail-order-view__customer-notes__value>{{notesCustomer}}</p>
			</div>

			<div class=retail-order-view__customer-notes v-if=order.referrer>
				<h4 class=retail-order-view__customer-notes__key>How did you hear about us?</h4>
				<p class=retail-order-view__customer-notes__value>{{order.referrer}}</p>
			</div>
		</div>

		<div class=retail-order-view__section>
			<h2 class=app-section-header>
				Shipping and billing details
				<div>
					<button v-if="userType=='admin'" class=app-section-header__action @click="editAddress ? updateOrder() : editAddress=true">
						{{editAddress ? 'Save' : 'Edit'}}
					</button>
					<button v-if="!formattedAddress && hasNonLatinData" class=app-section-header__action @click="translateAddress">Translate address</button>
					<a v-if="userType=='factory'" class='app-section-header__action' :href='shippingLabelUrl' target='_blank'>Print shipping label</a>
				</div>
			</h2>
			<div class=retail-order-view__address-details>
				<div class='retail-order-view__address-details__item retail-order-view__address-details__item_shipping'>
					<h4 class=retail-order-view__address-details__item__key>Shipping address</h4>
					<div class=retail-order-view__address-details__item__value>
						{{order.customer.name}}<br>
						<span v-if=!editAddress>
							{{order.customer.address.line1}}<br>
							{{order.customer.address.city}}<br>
							{{order.customer.address.postal_code}}<br>
							<template v-if="order.customer.address.state">
								{{order.customer.address.state}}<br>
							</template>
							{{order.customer.address.country}}
						</span>
						<span v-if=editAddress>
							<input v-model=order.customer.address.line1 /><br>
							<input v-model=order.customer.address.city /><br>
							<input v-model=order.customer.address.postal_code /><br>
							<input v-model=order.customer.address.state /><br>
							<input v-model=order.customer.address.country />
						</span>
					</div>
				</div>
				<div v-if=formattedAddress class='retail-order-view__address-details__item retail-order-view__address-details__item_shipping'>
					<h4 class=retail-order-view__address-details__item__key>Translated shipping address</h4>
					<span v-html="formattedAddress"></span>
				</div>
				<div v-if=tx?.extra?.address class='retail-order-view__address-details__item retail-order-view__address-details__item_billing'>
					<h4 class=retail-order-view__address-details__item__key>Billing address</h4>
					<div class=retail-order-view__address-details__item__value>
						{{order.customer.name}}<br>
						<span>
							{{tx?.extra?.address.line1}}<br>
							{{tx?.extra?.address.city}}<br>
							{{tx?.extra?.address.postal_code}}<br>
							{{tx?.extra?.address.state}}<br>
							{{tx?.extra?.address.country}}
						</span>
					</div>
				</div>
			</div>
		</div>


		<div v-if=notesFactoryEvent class='retail-order-view__section retail-order-view__factory-notes'>
			<h2 class=app-section-header>
				Notes for factory
				<button class=app-section-header__action @click="editNotes ? updateOrder() : editNotes=true">
					{{editNotes ? 'Save' : 'Edit'}}
				</button>
			</h2>
			<p v-if=!editNotes class=retail-order-view__factory-notes__value>{{notesFactoryEvent.message}}</p>
			<textarea class=order-edit__note__input v-if=editNotes v-model=notesFactoryEvent.message></textarea>
		</div>

		<div v-if=shippingInfo class='retail-order-view__section retail-order-view__shipping-info'>
			<h2 class=app-section-header>Shipping information</h2>
			<div class=app-kvlist>
				<div class=app-kvlist__group>
					<div class=app-kvlist__item>
						<div class=app-kvlist__key>Tracking number</div>
						<div class=app-kvlist__value>{{shippingInfo.trackingNumber}}</div>
					</div>
				</div>
				<div class=app-kvlist__group>
					<div class=app-kvlist__item>
						<div class=app-kvlist__key>Delivery Method</div>
						<div class=app-kvlist__value>{{shippingInfo.company}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="retail-order-view__section relative">
			<h2 class="app-section-header">Internal messages</h2>
			<react-wrapper v-if='orderId' />
		</div>

		<div class=retail-order-view__section>
			<h2 class=app-section-header>Order fulfilment status</h2>
			<retail-order-status class=retail-order-view__fulfilment-status :order=order></retail-order-status>
			<retail-order-activities :order=order :user-type=userType></retail-order-activities>
		</div>

		<retail-order-action :order=order :user-type=userType></retail-order-action>

	</section>
	`,
});
